import React from "react"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/SEO/SEO"
import "../../styles/app.scss"
import { Container, Section, Title } from "bloomer"
import "@reach/tabs/styles.css"
import { Link } from "gatsby"
import BlockContent from "../../components/Sanity/blockContent"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleRight } from "../../fontAwesome/pro-duotone-svg-icons"

class Accessibility extends React.Component {
  render() {
    const index = this.props.data.sanityAccessibility
    return (
      <main>
        <Layout>
          <SEO title="Accessibility" />
          <Section>
            <Container>
              <Title className="title is-1 introTitle" id="main">
                {index.title}
              </Title>
              <BlockContent blocks={index._rawBody} />
              <Link
                className="space-please general-links"
                to="/accessibility/accessibilityStatement"
              >
                <FontAwesomeIcon
                  icon={faChevronCircleRight}
                  className="faIconColors linkIcon"
                  role="pressentation"
                />{" "}
                Read our Accessibility statement
              </Link>
              <br />
              <Link
                className="space-please general-links"
                to="/accessibility/accessibilityForm"
              >
                <FontAwesomeIcon
                  icon={faChevronCircleRight}
                  className="faIconColors linkIcon"
                  role="pressentation"
                />{" "}
                Accessibility form
              </Link>
            </Container>
          </Section>
        </Layout>
      </main>
    )
  }
}
export default Accessibility

export const accessbilityQuery = graphql`
  query accessbilityQuery {
    sanityAccessibility {
      _rawBody
      title
    }
  }
`
